import { Component, OnInit } from '@angular/core';
// @ts-ignore
import * as JsDiff from '../../assets/diff.js'

@Component({
  selector: 'app-difftool',
  templateUrl: './difftool.component.html',
  styleUrl: './difftool.component.css'
})
export class DifftoolComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    const a = document.getElementById('a');
    const b = document.getElementById('b');
    const b1 = document.getElementById('b1');
    const b2 = document.getElementById('b2');
    const result = document.getElementById('result');
    const result1 = document.getElementById('result1');
    const result2 = document.getElementById('result2');

    function changed(sourceElement: HTMLElement, resultElement: HTMLElement) {
      const diffType = (window as any).diffType || 'diffChars'; // Default to diffChars if no type selected

      const aText = (a as HTMLElement).textContent!.toLowerCase();
      const sourceText = sourceElement.textContent!.toLowerCase();

      const diff = JsDiff[diffType](aText, sourceText);
      const fragment = document.createDocumentFragment();

      for (let i = 0; i < diff.length; i++) {
        if (diff[i].added && diff[i + 1] && diff[i + 1].removed) {
          const swap = diff[i];
          diff[i] = diff[i + 1];
          diff[i + 1] = swap;
        }

        let node;
        if (diff[i].removed) {
          node = document.createElement('del');
          node.style.color = '#b30000';
          node.style.background = '#f98892';
          node.appendChild(document.createTextNode(diff[i].value));
        } else if (diff[i].added) {
          node = document.createElement('ins');
          node.style.color = '#406619';
          node.style.background = '#6df142';
          node.appendChild(document.createTextNode(diff[i].value));
        } else {
          node = document.createTextNode(diff[i].value);
        }
        fragment.appendChild(node);
      }

      resultElement.textContent = '';
      resultElement.appendChild(fragment);
    }

    window.onload = function () {
      onDiffTypeChange(document.querySelector('#settings [name="diff_type"]:checked'));
      changed(b!, result!);
      changed(b1!, result1!);
      changed(b2!, result2!);
    };

    a!.addEventListener('input', function() {
      changed(b!, result!);
      changed(b1!, result1!);
      changed(b2!, result2!);
    })

    b!.addEventListener('input', function () {
      changed(b!, result!);
    });

    b1!.addEventListener('input', function () {
      changed(b1!, result1!);
    });

    b2!.addEventListener('input', function () {
      changed(b2!, result2!);
    });

    function onDiffTypeChange(radio: any) {
      (window as any).diffType = radio.value;
      document.title = "Diff " + radio.value.slice(4);
    }

    const radio = document.getElementsByName('diff_type');
    for (let i = 0; i < radio.length; i++) {
      radio[i].onchange = function(e: any) {
        onDiffTypeChange(e.target);
        changed(b!, result!);
        changed(b1!, result1!);
        changed(b2!, result2!);
      }
    }
  }
}

