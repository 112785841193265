  <nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <a class="navbar-brand fixed-right">&nbsp;&nbsp;LECT</a>
      <ul class="navbar-nav ml-auto">
         <li *ngIf="isUserLoggedIn" class="nav-item">
           <button class="btn btn-link nav-link border-0" role="button" (click)="logout()">Welcome, {{ this.userName }} | Sign Out&nbsp;&nbsp;</button>
         </li>
         <li *ngIf="!isUserLoggedIn" class="nav-item">
            <button class="btn btn-link nav-link border-0" role="button" (click)="login()">Sign In&nbsp;&nbsp;</button>
          </li>
      </ul>
  </nav>
