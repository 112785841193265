import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'Lect';

  isUserLoggedIn:boolean=false;
  userName?:string='';
  private readonly _destroy=new Subject<void>();
  userEmail?: string='';

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig:MsalGuardConfiguration,
  private msalBroadCastService:MsalBroadcastService,
  private authService:MsalService
  ) { }

  ngOnInit(): void {
    
    this.msalBroadCastService.inProgress$.pipe
    (filter((interactionStatus:InteractionStatus)=>
    interactionStatus==InteractionStatus.None),
    takeUntil(this._destroy))
    .subscribe(x=>
      {
        this.isUserLoggedIn=this.authService.instance.getAllAccounts().length>0;
      
        if(this.isUserLoggedIn)
        {
          this.userName = this.authService.instance.getAllAccounts()[0].name;
        }
        this.userEmail = this.authService.instance.getAllAccounts()[0].username;
        //var useremailid = {"employeeEmail": this.userEmail}
      })
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
   }

   login()
   {
     if(this.msalGuardConfig.authRequest)
     {
       this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
     }
     else
     {
       this.authService.loginRedirect();
     }
   }

   logout() { // Add log out function here
    this.authService.logoutRedirect({
      postLogoutRedirectUri: window.location.origin
    });
  }
}
