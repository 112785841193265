import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { RoleGuard } from './shared/role.guard';
import { roles } from './shared/auth-config';
import { DifftoolComponent } from './difftool/difftool.component';

const routes: Routes = [
  { 
    path: '**', redirectTo: 'difftool', pathMatch: 'full' },
  {
    path: 'difftool', component: DifftoolComponent , canActivate: [MsalGuard, RoleGuard], data: {
      expectedRoles: [roles.LectUser]
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }