<section>
<div id="settings">
    <label><input type="radio" name="diff_type" value="diffChars"> Chars</label>
    <label><input type="radio" name="diff_type" value="diffWords" checked> Words</label>
    <label><input type="radio" name="diff_type" value="diffLines"> Lines</label>
</div>
<table>
    <tr>
        <td rowspan="3" contenteditable="true" id="a">restaurant</td>
        <td contenteditable="true" id="b">aura</td>
        <td><pre id="result"></pre></td>
    </tr>
    <tr>
        <td contenteditable="true" id="b1">aura</td>
        <td><pre id="result1"></pre></td>
    </tr>
    <tr>
        <td contenteditable="true" id="b2">aura</td>
        <td><pre id="result2"></pre></td>
    </tr>
</table>
</section>